import styled from 'styled-components';
import { FlexCol, FlexRow } from '../../styles/styles';
import { MaxBoundarySection } from '../../styles/components/containers';
import { _H2M, _H4, _H5 } from '../../styles/components/header';

export const BCorpFeatureSectionContainer = styled(MaxBoundarySection)`
    .header-text {
      text-align: center;
    }
`;

export const BodyContainer = styled.div`
  ${FlexCol}

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    flex-direction: row;
  }
`;

export const SectionOne = styled.div`
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    width: 70%;
    padding-right: 40px;
  }

  ul {
    padding-left: 20px;
    margin-bottom: 0;
  }

  li {
    margin-bottom: 20px;
    text-align: left;
    ${_H5}
    font-weight: 400;

    &:last-of-type {
      margin-bottom: 0;
    }

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      ${_H4}
      font-weight: 400;
    }
  }
`;

export const SectionTwo = styled.div`
  ${FlexRow}
  justify-content: center;
  margin-top: 40px;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 30%;
    margin-top: 0;
  }

  .b-corp-logo {
    width: 70px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      width: 90px;
    }
  }

  .fact-container {
    ${FlexCol}
    justify-content: space-between;
    text-align: center;
    width: 160px;
    margin-left: 30px;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      margin-left: 0;
      justify-content: center;
    }
  }

  .percentage {
    ${_H2M}
  }
`;
