import { observer } from 'mobx-react';
import React from 'react';
import { H1, H3 } from '../../styles/components/header';
import { TestIdentitiesContainer, TestIdentitiesPageContainer, TestIdentityContainer } from './styles';

import { PMarketing } from '../../styles/components/paragraph';

interface IProps {
  className?: string;
}

const TestIdentitiesBase: React.FC<IProps> = () => {
  const renderTestIdentities = () => {
    const testIdentities = [
      {
        name: 'Henry David Thoreau',
        email: 'hthoreau@karmawallet.com',
        password: 'Walden1854!',
        description: 'Henry has a linked card and is enrolled in cashback rewards.',
        avatar: 'https://s3.amazonaws.com/cdn.karmawallet.io/test-identities/henry_david_thoreau.png',
        avatarDescription: 'a portrait of Henry David Thoreau composed of leaves and abstract shapes',
      },
      {
        name: 'Rachel Carson',
        email: 'rcarson@karmawallet.com',
        password: 'SilentSpring1962@',
        description: 'Rachel is a new user and hasn\'t linked a card yet.',
        avatar: 'https://s3.amazonaws.com/cdn.karmawallet.io/test-identities/rachel_carson.png',
        imgDescription: 'a portrait of Rachel Carson standing among leaves, flowers, and abstract shapes',
      },
      {
        name: 'George Washington Carver',
        email: 'gwcarver@karmawallet.com',
        password: 'CropRotation1930s#',
        description: 'George had linked one card, but it became unlinked in the transaction sync process.',
        avatar: 'https://s3.amazonaws.com/cdn.karmawallet.io/test-identities/george_washington_carver.png',
        imgDescription: 'a portrait of George Washington Carver composed of vegetables with a tile-like texture',
      },
      {
        name: 'Chico Mendes',
        email: 'cmendes@karmawallet.com',
        password: 'SaveTheRainforest1988$',
        description: 'Chico had linked one card, but has since removed it.',
        avatar: 'https://s3.amazonaws.com/cdn.karmawallet.io/test-identities/chico_mendes.png',
        imgDescription: 'a portrait of Chico Mendes standing in front of a densely forested area',
      },
    ];
    return testIdentities.map((identity) => (
      <div key={ identity.name.split(' ').join() } className='identity-card'>
        <H3 className='identity-header'>{ identity.name }</H3>
        <div className='identity-data'>
          <img className='data-image' alt={ identity.avatarDescription } src={ identity.avatar } />

          <div className='identity-details'>
            <div className='data-item'>
              <p className='label'>name:</p>
              <p>{ identity.name }</p>
            </div>
            <div className='data-item'>
              <p className='label'>email:</p>
              <p>{ identity.email }</p>
            </div>
            <div className='data-item'>
              <p className='label'>password:</p>
              <p>{ identity.password }</p>
            </div>
            <div className='data-item'>
              <p className='label'>description:</p>
              <p>{ identity.description }</p>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div>
      <TestIdentitiesPageContainer appType='developer'>
        <TestIdentitiesContainer>
          <H1>Test Identities</H1>
          <img
            className='header-image'
            alt='science lab in a rainforest'
            src='https://s3.amazonaws.com/cdn.karmawallet.io/test-identities/lab_in_forest.png'
          />
          <div className='test-data-intro'>
            <PMarketing>
              Test identities can be used to log into the <a href='https://frontend.demo.karmawallet.io'>karmawallet demo environment</a>{ ' ' }
              and test different scenarios or actions.
            </PMarketing>
          </div>
          <TestIdentityContainer>{ renderTestIdentities() }</TestIdentityContainer>
        </TestIdentitiesContainer>
      </TestIdentitiesPageContainer>
    </div>
  );
};

export const TestIdentities = observer(TestIdentitiesBase);
