import styled from 'styled-components';
import { BodyContainer } from '../../components/BCorpFeatureSection/styles';
import { MainContainer } from '../../components/MainContainer';
import { TextContainer } from '../../components/OurImpactCard/styles';
import { FlexCol, FlexColCenter, FlexRow } from '../../styles/styles';

export const TestIdentitiesPageContainer = styled(MainContainer)``;

export const TestIdentitiesContainer = styled(BodyContainer)`
  position: relative;
  scroll: auto;
  margin-top: 7rem;
  ${FlexColCenter};
  align-items: center;
  height: 100%;

  .header-image {
    position: sticky;
    top: 150px;
    z-index: -1;
    width: 100vw;
    height: 70vh;
    object-fit: cover;
    margin-bottom: 3rem;
    margin-top: 3rem;

    @media (${({ theme }) => theme.breakpoints.mdMax}) {
      height: 40em;
    }
  }

  .test-data-intro {
    position: absolute;
    top: 18em;
    margin: auto;
    background-color: ${({ theme }) => theme.colors.light};
    width: 75%;
    padding: 2rem;
    border-radius: 1rem;
    text-align: center;
    max-width: 60rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

export const TestIdentityContainer = styled(TextContainer)`
  margin-top: 4rem;
  margin-bottom: 12rem;
  ${FlexColCenter};
  align-items: center;
  gap: 10rem;
  height: 100%;

  .identity-card {
    max-width: 60rem;
    width: 80%;
    background-color: ${({ theme }) => theme.colors.light};
    padding: 1rem;
    border-radius: 1rem;
    margin: auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .identity-header {
      text-align: center;
      margin: 1rem;
    }

    .identity-data {
      ${FlexCol};
      align-items: center;
      justify-content: center;
      text-align: left;
      gap: 2rem;

      .data-image {
        width: 16rem;
        object-fit: cover;
        border-radius: 50%;
      }

      .identity-details {
        margin-left: 4rem;
        margin-right: 4rem;
        ${FlexCol};
        align-items: start;
        justify-content: center;

        .data-item {
          ${FlexRow};
          align-items: center;
          justify-content: center;
          font-size: 18px;
          gap: 2rem;

          .label {
            width: 5rem;
            text-align: right;
            font-weight: 600;
          }
        }
      }

      @media (${({ theme }) => theme.breakpoints.mdMin}) {
        ${FlexRow};
        .data-image {
          width: 20rem;
        }

        .identity-details {
          margin-left: 0;
          margin-right: 0;
          .data-item {
            .label {
              width: 5rem;
            }
          }
        }
      }
    }
  }
`;
