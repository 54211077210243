import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';
import { FlexColCenter } from '../../styles/styles';

export const APIDocsContainer = styled(MainContainer)``;

export const DocsContainer = styled.div`
  margin-top: 7rem;
  ${FlexColCenter}
  align-items: center;
  height: 100%;

  * {
    color: unset;
  }

  .model-container {
    display: flex;
  }

  .property-row td {
    font-size: 14px;
  }

  .model {
    width: 100%;

    p {
      width: 100%;
    }

    .description {
      display: inline-block;
      margin-top: 20px;
    }
  }

  .property-row {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
  }

  .model .prop .prop-type {
    text-align: center;
    padding: 5px;
    width: 100%;
  }
`;
