import styled from 'styled-components';
import { Section } from '../../styles/components/containers';

export const HeroSectionBackgroundWrapper = styled(Section)`
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  min-height: 150%;
  overflow: hidden;
  position: absolute;
  width: 100%;

  svg {
    bottom: 3vw;
    height: auto;
    min-height: 100vh;
    min-width: 2000px;
    position: absolute;
    width: 120vw;

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      bottom: 3vw;
      height: auto;
      min-height: 100vh;
      min-width: 2000px;
      position: absolute;
      width: 120vw;
    }
  }
`;
