import { observer } from 'mobx-react';
import React from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { H1, H2 } from '../../styles/components/header';
import { ButtonLink } from '../Button';
import { ButtonKind } from '../Button/styles';
import { HeroSectionBackground } from '../HeroSectionBackground';
import {
  DeveloperHeroSectionContainer,
  DeveloperHeroSectionInner,
} from './styles';

interface IProps {
  className?: string;
}

const DeveloperHeroSectionBase: React.FC<IProps> = ({ className = '' }) => {
  const analytics = useAnalytics();

  const onTestIdentitesClick = () => {
    analytics.fireEvent('HP_Developer_Hero_GetStarted_Click');
  };
  const onGetStartedClick = () => {
    analytics.fireEvent('HP_Developer_TestIdentities_Click');
  };

  return (
    <DeveloperHeroSectionContainer className={ className }>
      <HeroSectionBackground />
      <DeveloperHeroSectionInner>
        <div className='info-container'>
          <H1>Live Your Values</H1>
          <H2>Let's build a sustainable future together</H2>
          <div className='button-container'>
            <ButtonLink
              className='hero-button'
              href={ '/api-docs' }
              kind={ ButtonKind.Primary }
              onClick={ onGetStartedClick }
            >
              API Docs
            </ButtonLink>
            <ButtonLink className='hero-button test-identities-button' href={ '/test-identities' } kind={ ButtonKind.Primary } onClick={ onTestIdentitesClick }>
              Test Identities
            </ButtonLink>
          </div>
        </div>
      </DeveloperHeroSectionInner>
    </DeveloperHeroSectionContainer>
  );
};

export const DeveloperHeroSection = observer(DeveloperHeroSectionBase);
