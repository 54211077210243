import styled from 'styled-components';
import { MainContainer } from '../../components/MainContainer';
import { FlexColCenter } from '../../styles/styles';

export const SDKPageContainer = styled(MainContainer)``;

export const SDKHeader = styled.h1`
  margin-bottom: 3rem;
`;

export const SDKContainer = styled.div`
  position: relative;
  scroll: auto;
  margin-top: 7rem;
  ${FlexColCenter};
  align-items: center;
  height: 100%;
  font-size: 20px;

  .sdk-header {
    margin-bottom: 4rem;
  }

  section.generate-sdk {
    margin-top: 3rem;
    margin-bottom: 12rem;
    ${FlexColCenter};
    align-items: center;
    gap: 2rem;
    height: 100%;

    ol li {
      margin-bottom: 1rem;
      font-size: 18px;
    }

    ol li a {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: none;
    }

    ol li a:hover {
      color: ${({ theme }) => theme.colors.darkGray3};
    }

    ol li span.generate-client-text {
      font-weight: 600;
    }
  }
`;
