import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { config, useSpring } from 'react-spring';
import { withTheme } from 'styled-components';
import { FRONTEND_URL } from '../../constants';
import { useAnalytics } from '../../contexts/analytics-store';
import { IThemeProps } from '../../styles/themes';
import { Button } from '../Button';
import { ButtonKind } from '../Button/styles';
import { SocialLinks } from '../SocialLinks';
import { XIcon } from '../svgs/icons/XIcon';
import {
  DesktopNavContainer,
  DeveloperAppHeaderContainer,
  MobileHeaderNavContainer,
  MobileNav,
  MobileNavFooter,
  MobileNavItemsContainer,
  NavItemsContainer,
  SocialContainer,
} from './styles';
import { useHeader } from '../../contexts/header';
import { AsymHamburgerMenuIcon } from '../svgs/icons/HamburgerMenuIcon';

enum NavLinkType {
  Documentation = 'documentation',
  Sdk = 'sdk',
  KarmaWallet = 'karma-wallet',
  TestIdentities = 'test-identities',
}

interface IProps extends IThemeProps {
  className?: string;
}

interface INavLink {
  id: NavLinkType;
  text: string;
  pathname?: string;
  url?: string;
}

const navLinks: INavLink[] = [
  {
    id: NavLinkType.Documentation,
    text: 'API Docs',
    pathname: '/api-docs',
  },
  {
    id: NavLinkType.Sdk,
    text: 'SDK',
    pathname: '/sdk',
  },
  {
    id: NavLinkType.TestIdentities,
    text: 'Test Identities',
    pathname: '/test-identities',
  },
  {
    id: NavLinkType.KarmaWallet,
    text: 'Karma Wallet',
    pathname: '/karma-wallet',
  },
];

const baseFrom = {
  height: '0px',
  paddingTop: '0px',
  paddingBottom: '0px',
};

const baseTo = {
  paddingTop: '0px',
  paddingBottom: '0px',
};

const mobileNavFrom = { ...baseFrom };

const desktopNavFrom = {
  width: '420px',
  opacity: 1,
};

const desktopNavTo = {
  width: '0px',
  opacity: 0,
};

const DeveloperAppHeaderBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const location = useLocation();
  const analytics = useAnalytics();
  const headerModel = useHeader();
  const mobileNavEngaged = useRef(false);
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);
  const ref = useRef(null);

  useLayoutEffect(() => {
    if (!ref.current) return;
    headerModel.setHeaderHeight(ref.current.clientHeight);
  }, [ref.current]);

  const mobileNavSpring = useSpring({
    config: config.gentle,
    from:
      mobileNavIsOpen || !mobileNavEngaged.current
        ? mobileNavFrom
        : { ...baseTo, height: `${window.innerHeight - 75}px` },
    to: mobileNavIsOpen ? { ...baseTo, height: `${window.innerHeight - 75}px` } : mobileNavFrom,
  });

  const desktopNavSpring = useSpring({
    config: config.gentle,
    from: desktopNavTo,
    to: desktopNavFrom,
  });

  useEffect(() => {
    // prevent scrolling when this modal is open
    document.body.classList[mobileNavIsOpen ? 'add' : 'remove']('no-scroll');
  }, [mobileNavIsOpen]);

  const onHamburgerClick = useCallback(() => {
    mobileNavEngaged.current = true;
    const isOpen = !mobileNavIsOpen;
    setMobileNavIsOpen(isOpen);
    analytics.fireEvent(`Developer_MobileNavHamburgerClicked_${isOpen ? 'open' : 'close'}`);
  }, [mobileNavIsOpen]);

  const onNavLinkClick = useCallback(
    (linkId: NavLinkType) => () => {
      setMobileNavIsOpen(false);
      analytics.fireEvent(`Developer_AppNavLinkClick_${linkId}`);
    },
    [],
  );

  const onLinkClick = useCallback(
    (linkName: string) => () => {
      analytics.fireEvent(`Developer_MobileSubNav_${linkName}`);
    },
    [],
  );

  const renderMobileNav = () => {
    const content: JSX.Element[] = [];

    content.push(
      <Button
        key='nav-item-hamburger'
        className={ `mobile-nav-item ${mobileNavIsOpen ? 'is-open' : ''}` }
        kind={ ButtonKind.Blank }
        onClick={ onHamburgerClick }
      >
        { mobileNavIsOpen ? <XIcon stroke={ theme.colors.primary } /> : <AsymHamburgerMenuIcon fill={ theme.colors.primary } /> }
      </Button>,
    );

    const copyright = `©${dayjs().format('YYYY')} ImpactKarma Inc. All rights reserved.`;
    const mobileNav = (
      <MobileNav className={ mobileNavIsOpen ? 'is-open' : '' } style={ mobileNavSpring }>
        <div>{ renderNavLinks('mobile-nav-links') }</div>
        <MobileNavFooter>
          <SocialContainer>
            <div className='social-header'>Follow us</div>
            <SocialLinks className='mobile-nav-social-links' />
          </SocialContainer>
          <p>{ copyright }</p>
          <div className='mobile-nav-sub-links'>
            <a
              onClick={ onLinkClick('Privacy') }
              target='_blank'
              href='/privacy-policy'
              rel='noreferrer'
            >
              Privacy Policy
            </a>
            <a
              onClick={ onLinkClick('PathwardPrivacy') }
              target='_blank'
              href='https://cdn.karmawallet.io/documents/pathward_privacy_policy.pdf'
              rel='noreferrer'
            >
              Pathward Privacy Policy
            </a>
            <a
              onClick={ onLinkClick('CardHolderAgreement') }
              target='_blank'
              href='https://cdn.karmawallet.io/documents/karma_wallet_pathward_cardholder_agreement.pdf'
              rel='noreferrer'
            >
              Cardholder Agreement
            </a>
            <a
              onClick={ onLinkClick('TermsConditions') }
              target='_blank'
              href='https://cdn.karmawallet.io/terms_of_service.pdf'
              rel='noreferrer'
            >
              Terms & Conditions
            </a>
          </div>
        </MobileNavFooter>
      </MobileNav>
    );

    return (
      <MobileNavItemsContainer>
        { content }
        { mobileNav }
      </MobileNavItemsContainer>
    );
  };

  const renderNavLinks = (navItemsClassName = '') => {
    const links = navLinks.map(({ id, text, pathname }) => {
      /* external link to main site */
      if (pathname === '/karma-wallet') {
        return (
          <a key={ id } className={ 'nav-link' } href={ FRONTEND_URL } target='_blank' rel='noreferrer'>
            { text }
          </a>
        );
      }
      return (
        <Link
          key={ id }
          className={ `nav-link ${pathname === location.pathname ? 'active' : ''}` }
          to={ { pathname: pathname } }
          onClick={ onNavLinkClick(id) }
        >
          { text }
        </Link>
      );
    });

    return (
      <NavItemsContainer
        className={ navItemsClassName }
        style={ navItemsClassName === 'mobile-nav-links' ? null : desktopNavSpring }
      >
        { links }
      </NavItemsContainer>
    );
  };

  return (
    <DeveloperAppHeaderContainer app='developer' className={ className } navClassName='global-app-header'>
      <DesktopNavContainer>{ renderNavLinks('desktop-nav-links') }</DesktopNavContainer>
      <MobileHeaderNavContainer>{ renderMobileNav() }</MobileHeaderNavContainer>
    </DeveloperAppHeaderContainer>
  );
};

const DeveloperAppHeaderAsObserver = observer(DeveloperAppHeaderBase);
export const DeveloperAppHeader = withTheme(DeveloperAppHeaderAsObserver);
