import React from 'react';
import { DeveloperHeroSection } from '../../../components/DeveloperHeroSection';
import { ActionsListContainer, HomeContainer } from './styles';

interface IProps {
  className?: string;
}

export const Home: React.FC<IProps> = ({ className = '' }) => (
  <HomeContainer appType='developer' className={ className } title='Developer'>
    <ActionsListContainer>
      <DeveloperHeroSection />
    </ActionsListContainer>
  </HomeContainer>
);
