import { observer } from 'mobx-react';
import React from 'react';
/* import * as snippets from "../../../openapi/snippets.json"; */
import 'swagger-ui-react/swagger-ui.css';
import { DEVELOPER_URL } from '../../constants';
import { H1 } from '../../styles/components/header';
import { SDKContainer, SDKPageContainer } from './styles';

interface IProps {
  className?: string;
}

const SDKBase: React.FC<IProps> = () => (
  <SDKPageContainer appType='developer'>
    <SDKContainer>
      <H1 className='sdk-header'>SDK</H1>
      <div className='sdk-intro'>Use our OpenAPI specification to generate a client SDK for your favorite language.</div>
      <section className='generate-sdk'>
        <ol className='generate-sdk-instructions'>
          <li>
            Navigate to the <a className='swagger-editor-link' target='_blank' rel='noopener noreferrer' href='https://editor.swagger.io/'>Swagger Editor</a>
          </li>
          <li>
           Copy the contents of <a className='openapi-definition-link' target='_blank' rel='noopener noreferrer' href={ DEVELOPER_URL + '/openapi/karma-wallet.json' }>karma-wallet.json</a> and paste it into the swagger editor
          </li>
          <li>
            Click <span className='generate-client-text'>Generate Client</span> and select your preferred language
          </li>
          <li>
            Inspire positive change by making sustainability data accessible to your users!
          </li>
        </ol>
      </section>
    </SDKContainer>
  </SDKPageContainer>
);

export const SDK = observer(SDKBase);
