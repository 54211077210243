import styled from 'styled-components';
import { MaxBoundaryContainer, Section } from '../../styles/components/containers';
import { FlexColCenter, FlexHorizontalCenter, FlexRow } from '../../styles/styles';

export const DeveloperHeroSectionContainer = styled(Section)`
  margin: 0 auto;
  position: relative;
`;

export const DeveloperHeroSectionInner = styled(MaxBoundaryContainer)`
  ${FlexHorizontalCenter}
  gap: 25px;
  flex-direction: column;
  position: relative;
  z-index: 9;
  height: 360px;
  margin-top: 25px;
  width: 100%;
  text-align: center;

  h1 {
    color: ${({ theme }) => theme.colors.white};
    font-size: 32px;
    font-weight: 700;
    line-height: 2.25rem;
    margin-bottom: 20px;

    @media (${({ theme }) => theme.breakpoints.mdMin}) {
      font-size: 36px;
    }
  }

  h2 {
    color: ${({ theme }) => theme.colors.darkGray1};
    font-size: 16px;
    font-weight: 700;
    line-height: 2.25rem;
  }

  .button-container {
    width: 100%;
    ${FlexColCenter}
    align-items: center;
    gap: 1rem;
    margin-top: 30px;

    .hero-button {
      font-size: 12px;
      background: ${({ theme }) => theme.colors.darkYellow1};
      border: none;
      color: ${({ theme }) => theme.colors.darkGray1};
      margin: 0;
      padding: 10px 20px;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    ${FlexRow}
    align-items: center;
    height: 100%;

    .info-container {
      margin-top: 40px;
      width: 100%;
      ${FlexColCenter}
      align-items: flex-start;
      gap: 40px;
      height: 500px;
      z-index: 99;

      h1 {
        align-self: flex-start;
        font-size: 60px;
      }

      h2 {
        font-size: 22px;
      }

      .button-container {
        ${FlexRow}
        flex-direction: row;
        justify-content: start;
        align-items: start;
        gap: 2rem;

        .hero-button {
          font-size: 18px;
        }
      }
    }
  }
`;
