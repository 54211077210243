import React from 'react';
import { HeroBackground } from '../svgs/graphics/HeroBackground';
import { HeroSectionBackgroundWrapper } from './styles';

interface IProps {
  className?: string;
}

export const HeroSectionBackground: React.FC<IProps> = ({
  className = '',
}) => (
  <HeroSectionBackgroundWrapper className={ className }>
    <HeroBackground />
  </HeroSectionBackgroundWrapper>
);
