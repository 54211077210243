import React, { FC, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter } from 'react-router-dom';
import { BadgesStore } from '../../contexts/badges';
import { ThemeStore } from '../../contexts/theme-store';
import { UserSessionStore } from '../../contexts/user';
import { GlobalStyles } from '../../styles/styles';

import { MainDeveloperRouter } from '../MainDeveloperRouter';

import { ThemeProvider } from '../ThemeProvider';
import { SocketStore } from '../../contexts/socket-client';
import { ToasterStore } from '../../contexts/toaster-store';
import { Toaster } from '../Toaster';
import { ErrorMessagesStore } from '../../contexts/error-messages-store';
import { GlobalErrorModal } from '../modals/GlobalErrorModal';
import { AnalyticsStore } from '../../contexts/analytics-store';
import { GATrackingId } from '../../models/analytics';
import { AppDataStore } from '../../contexts/app';
import { BannersStore } from '../../contexts/banners';
import { DeveloperAppHeader } from '../DeveloperAppHeader';
import { HeaderStore } from '../../contexts/header';

interface IProps {
  className?: 'string';
}

const DeveloperBase: FC<IProps> = () => {
  const initializeGA = async () => {
    try {
      ReactGA.initialize(GATrackingId);
    } catch (error) {
      //eslint-disable-next-line no-console
      console.error('Error initializing Google Analytics', error);
    }
  };

  useEffect(() => {
    initializeGA();
  }, []);

  return (
    <AppDataStore>
      <ErrorMessagesStore>
        <ToasterStore>
          <ThemeStore>
            <ThemeProvider>
              <SocketStore>
                <UserSessionStore app='developer'>
                  <AnalyticsStore>
                    <BadgesStore>
                      <BannersStore>
                        <GlobalStyles />
                        <BrowserRouter>
                          <HeaderStore>
                            <DeveloperAppHeader />
                            <MainDeveloperRouter />
                          </HeaderStore>
                          <GlobalErrorModal />
                          <Toaster />
                        </BrowserRouter>
                      </BannersStore>
                    </BadgesStore>
                  </AnalyticsStore>
                </UserSessionStore>
              </SocketStore>
            </ThemeProvider>
          </ThemeStore>
        </ToasterStore>
      </ErrorMessagesStore>
    </AppDataStore>
  );
};

export const DeveloperApp = DeveloperBase;
