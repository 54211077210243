import { observer } from 'mobx-react';
import React from 'react';
/* import * as snippets from "../../../openapi/snippets.json"; */
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import * as spec from '../../../public/openapi/karma-wallet.json';
import { H1 } from '../../styles/components/header';
import { APIDocsContainer, DocsContainer } from './styles';

interface IProps {
  className?: string;
}

const APIDocsBase: React.FC<IProps> = () => (
  <APIDocsContainer appType='developer'>
    <DocsContainer>
      <H1>API Docs</H1>
      <SwaggerUI spec={ spec } docExpansion='list' defaultModelsExpandDepth={ 1 } defaultModelExpandDepth={ 1 } defaultModelRendering='example' requestSnippetsEnabled={ true } />
    </DocsContainer>
  </APIDocsContainer>
);

export const APIDocs = observer(APIDocsBase);
