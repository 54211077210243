import { observer } from 'mobx-react';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Home } from '../../pages/developer/Home';
import { APIDocs } from '../../pages/APIDocs';
import { TestIdentities } from '../../pages/TestIdentities';
import { SDK } from '../../pages/SDK';
import { PrivacyPolicy } from '../../pages/PrivacyPolicy';

export const MainDeveloperRouterBase: React.FC = () => {
  const renderHome = () => <Home />;

  return (
    <Routes>
      <Route path='/' element={ renderHome() } />
      <Route path='/api-docs' element={ <APIDocs /> } />
      <Route path='/sdk' element={ <SDK /> } />
      <Route path='/test-identities' element={ <TestIdentities /> } />
      <Route path='/privacy-policy' element={ <PrivacyPolicy /> } />
      <Route element={ <div>Page not found</div> } />
    </Routes>
  );
};

export const MainDeveloperRouter = observer(MainDeveloperRouterBase);
