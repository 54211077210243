import { animated } from 'react-spring';
import styled from 'styled-components';
import {
  FlexCenter,
  FlexCol,
  FlexHorizontalCenter,
  InsetAndOuterBoxShadow,
  InsetBoxShadow,
  PrimaryFont,
} from '../../styles/styles';
import { GlobalHeader } from '../GlobalHeader';

export const DeveloperAppHeaderContainer = styled(GlobalHeader)`
  position: fixed;
  
  .desktop-nav-global-search {
    flex-grow: 1;
    margin-right: 10px;
  }

  .global-app-header {
    justify-content: flex-end;
  }

  #create-account-button, #signin-button, .nav-link {
    color: ${({ theme }) => theme.colors.darkGray1};

    @media (${({ theme }) => theme.breakpoints.lgMin}) {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const DesktopNavContainer = styled.div`
  ${FlexHorizontalCenter}
  justify-content: space-between;
  align-self: stretch;
  align-items: stretch;
  display: none;
  min-width: 100%;
  padding-left: 35px;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    display: flex;
  }
`;

export const MobileHeaderNavContainer = styled.div`
  ${FlexHorizontalCenter}
  align-self: stretch;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    display: none;
  }
`;

export const MobileNav = styled(animated.div)`
  ${FlexCol}
  ${InsetBoxShadow}
  justify-content: space-between;
  position: fixed;
  top: 75px;
  left: 0;
  width: 100vw;
  height: 0;
  background: #fff;
  z-index: 10;
  overflow: hidden;

  .mobile-nav-links {
    ${FlexCol}
    padding: 0;

    a {
      justify-content: flex-start;
      padding: 15px 30px;
      border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightGray2}`};

      &.active:before {
        display: none;
      }
    }
  }

  .mobile-nav-alt-buttons {
    ${FlexCenter}
    ${InsetBoxShadow}
    width: 100% !important; // needed in order to override react-sprint styling
    padding: 15px;
    background: ${({ theme }) => theme.colors.lightGreen2};
  }

  .mobile-nav-global-search {
    padding: 25px 15px;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightGray2}`};
  }
`;

export const MobileNavFooter = styled.div`
  padding-bottom: 15px;

  p {
    margin-bottom: 5px;
    color: ${({ theme }) => theme.colors.darkGray2};
    text-align: center;
  }

  .mobile-nav-sub-links {
    ${FlexHorizontalCenter}
    justify-content: center;

    a {
      display: block;
      padding: 0 10px;
      color: ${({ theme }) => theme.colors.darkGray2};

      &:not(:last-child) {
        border-right: ${({ theme }) => `1px solid ${theme.colors.darkGray2}`};
      }
    }
  }
`;

export const MobileNavItemsContainer = styled.div`
  ${FlexHorizontalCenter}
  align-self: stretch;
  align-items: center;
  position: relative;
  min-width: 36px;

  .mobile-nav-item {
    position: relative;
    align-self: stretch;
    overflow: visible;

    &.mobile-avatar {
      height: auto;
      margin-right: 10px;
    }
  }

  button.mobile-nav-item {
    ${FlexCenter}
    width: 30px;
    border-radius: 0;
  }

  .mobile-nav-item.is-open {
    &:before,
    &:after {
      content: " ";
      position: absolute;
    }

    &:before {
      top: 50%;
      left: 50%;
      width: 34px;
      height: 34px;
      transform: translate(-50%, -50%);
    }

    &:after {
      bottom: 0;
      left: -2px;
      right: -2px;
      height: 6px;
      background: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const MobileUserAccountMenu = styled(MobileNav)`
  ${InsetAndOuterBoxShadow};
`;

export const MobileUserAccountMenuOverlay = styled(animated.div)`
  position: fixed;
  top: 75px;
  left: 0;
  display: block;
  width: 100vw;
  height: 0;
  z-index: 9;
  overflow: hidden;
`;

export const NavItemsContainer = styled(animated.div)`
  ${FlexHorizontalCenter}

  &.desktop-nav-links * {
    white-space: nowrap;
  }
`;

export const AltHeaderItemsContainer = styled(NavItemsContainer)`
  justify-content: flex-end;
  min-width: 300px;

  &.small {
    min-width: 115px;
  }

  button {
    padding: 0 15px;
    color: ${({ theme }) => theme.colors.darkGray3};
    white-space: nowrap;
    font-weight: 400;

    &:not(:last-child):not(.desktop-search-button) {
      border-right: 2px solid ${({ theme }) => theme.colors.darkGray1};
      border-radius: 0;
    }
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    button {
      &:not(:last-child):not(.desktop-search-button) {
        border-right: 2px solid #fff !important;
        border-radius: 0;
      }
    }
  }

  @media (${({ theme }) => theme.breakpoints.mdMax}) {
    .desktop-search-button {
      display: none;
    }
  }
`;

export const SocialContainer = styled.div`
  ${FlexCol}
  align-items: center;
  margin-bottom: 30px;

  .social-header {
    ${PrimaryFont}
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 700;
  }

  .mobile-nav-social-links {
    ${FlexHorizontalCenter}

    .social-icon {
      min-width: 30px;
      max-width: 30px;
      min-height: 30px;
      max-height: 30px;
    }
  }
`;
